<template>
  <div class="Index">
    <SelectDeep v-on:deepvalue="getDeepValue"/>
    <el-row>
      <el-col :span="10">
        <div class="grid-content bg-purple">
          <el-input
              type="textarea"
              rows="20"
              placeholder="请输入内容"
              v-model="textarea">
          </el-input>
        </div>
      </el-col>
      <el-col :span="10">
        <el-input
            type="textarea"
            :rows="20"
            v-model="returnText.data">
        </el-input>
      </el-col>
    </el-row>
    <el-button v-on:click="fetchData">提交</el-button>
  </div>
</template>

<script>
import SelectDeep from "@/components/SelectDeep";

export default {
  name: "index",
  components: {
    SelectDeep
  },
  data() {
    return {
      value: '',
      textarea: '',
      returnText: '',
      deep: 1,
      errored: false
    }
  },
  methods: {
    fetchData() {
      this.axios.put('http://home.yang2000ling.cn:10086/', {
        "data": this.textarea,
        "deep": this.deep
      }).then(response => (this.returnText = response.data))
    },
    getDeepValue(msg) {
      this.deep=msg
    }
  }
}
</script>

<style scoped>
p {
  text-align: justify;
}
</style>