<template>
  <el-select v-model="value" placeholder="请选择" @change="$emit('deepvalue',value)">
    <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "SelectDeep",
  emits: ['deepvalue'],
  data() {
    return {
      options: [
        {
          value: '1',
          label: '替换深度1：伪原创词典'
        },
        {
          value: '2',
          label: '替换深度2：同义词词林'
        },
        {
          value: '3',
          label: '替换深度3：伪原创+词林'
        }
      ],
      value: ''
    }
  }
}
</script>

<style scoped>

</style>